<template>
  <div class="sub-panel">
    <nav class="panel">
      <SubPanelHeader :record="record"
                      :category="getCategory"
                      label="Calendar"
                      v-on:hidesubpanel="closePanel"
                      v-on:clippanel="clipPanel"
      >
      </SubPanelHeader>
      <div class="panel-block">
        <Calendar :propPath="getPath"
                  :propAssetId="record.entityId"
                  :propAssetType="record.entityType">
        </Calendar>
      </div>
    </nav>
  </div>
</template>

<script>
import SubPanel from './subpanel';
import Calendar from '../../calendar';

export default {
  extends: SubPanel,
  components: {
    Calendar
  },
  computed: {
    getPath: function() {
      if (this.record.entityType == 'application') {
        return `/applications/${this.record.entityId}/timeline`
      } else if (this.record.entityType == 'company' && this.mode == 'clinical_trials') {
        return `/company/${this.record.entityId}/clinical_trials`
      } else if (this.record.entityType == 'disease' && this.mode == 'clinical_trials') {
        return `/disease/${this.record.entityId}/clinical_trials`
      }
    },
    getCategory: function() {
      return this.record.entityType
    }
  },
  methods: {}

}
</script>
