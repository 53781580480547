<template>
  <div style="margin-top: 20px;">
    <form @submit.prevent="executeCardSubmission" v-show="!isLoading" method="post" id="payment-form">

      <div>
        <label for="label card-element" class="title">
          Credit or debit card
        </label>
      </div>
      <div class="field is-grouped" style="margin-top: 20px;">
        <p class="control is-expanded">
          <div id="card-element" style="width: 90%"></div>

        </p>
        <p class="control">
          <button class="button is-primary checkout-btn">Submit Payment</button>
        </p>
      </div>
      <div id="card-errors" role="alert">{{ libpatentErrors}}</div>

    </form>

    <div v-show="isLoading">
      <div class="loader big-loader" ></div>
    </div>

  </div>
</template>

<script>
import axios from 'axios'

export default {
  data: function() {
    return {
      cardTokenPath: '/account/checkout',
      stripe: '',
      stripCard: '',
      libpatentErrors: '',
      plan: '',
      isLoading: false
    }
  },
  props: ['selectedPlan', 'token', 'stripetoken'],
  mounted: function() {
    this.stripe = Stripe(this.stripetoken)

    var elements = this.stripe.elements();
    var style = {
      base: {
        fontSize: '16px',
        color: "#32325d",
      }
    }

    var card = elements.create('card', {style: style})
    card.mount('#card-element')
    card.addEventListener('change', function(event) {
      var displayError = document.getElementById('card-errors')
      if (event.error) {
        displayError.textContent = event.error.message
      } else {
        displayError.textContent = ''
      }
    })

    this.stripeCard = card

  },
  methods: {
    executeCardSubmission: function (event) {
     this.stripe.createSource(this.stripeCard, {}).then( (result) => {
        if (result.error) {
          var errorElement = document.getElementById('card-errors');
          errorElement.textContent = result.error.message;
        } else {
          this.saveCardToken(result.source)
        }
      })
    },
    saveCardToken: function(stripeSource) {
      this.isLoading = true
      axios.post(this.cardTokenPath, {
        authenticity_token: this.token,
        stripe_token: stripeSource,
        plan: this.selectedPlan
      })
      .then((response) => {
        this.$emit('checkoutsuccess', {})
        this.isLoading = false
      })
      .catch((error) => {
        libpatentErrors: 'Something terrible happened.  Sorry this happened here.  Email me anytime at berrydigital@gmail.com.com or call me at 503.740.0198'
        this.isLoading = false
      })


    }
  }

}
</script>

