<template>
  <div id="search-app" class="search-app">

    <div id="search-interface" v-show="!searchComplete">
      <div class="field has-addons has-addons-centered search-field">
        <p class="control is-expanded has-icons-left has-icons-right">
          <input
            id="mnsrchinp"
            class="input main-search is-large"
            @input="handleInput"
            @blur="handleBlur"
            @focus="handleFocus"
            @keydown="handleKeyDown"
            autocomplete="off"
            type="text"
            v-model.trim="query"
            ref="search"
            placeholder="Search ..."
           />

           <span class="icon is-right" v-show="searchInProgress">
             <div class="loader search-loader" ></div>
           </span>

           <span class="icon is-small is-right has-text-danger" style="" v-show="searchFailed">
             <svg class="icon__cnt is-large">
               <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#ei-exclamation-icon"></use>
             </svg>
           </span>

           <span class="icon is-small is-left">
             <svg class='icon__cnt'><use xlink:href='#ei-search-icon'/></svg>
           </span>
        </p>

        <span class="faux-button" v-show="results.length && !searchFailed && query.length > 0 && !searchInProgress" @click="executeSearch()">
         <div class="icon--ei-arrow-right icon--m">
           <svg class="icon__cnt  pulse-success-icon">
             <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#ei-arrow-right-icon"></use>
           </svg>
         </div>
        </span>

      </div>

      <transition name="fade">
        <div>
          <div v-show="displayAutoComplete && results.length == 0 && this.query == ''" id="qckhlp" class="autocomplete-data is-large">
            <ul>
              <li>
                Search by entering a ...<br/>
                <span style="font-size: 70%;">
                  <span class="company-txt">Company</span>,
                  <span class="target-txt">target</span>,
                  <span class="disease-txt">disease</span>,
                  or topic
                </span>
              </li>
            </ul>
          </div>
          <div v-show="displayAutoComplete && results.length > 0" id="mnsrchauto" class="autocomplete-data is-large">
            <ul>
              <li v-for="(result, i) in results" v-bind:key="i" :class="entryClass(i)" @mousemove="mousemove(i)" @click="executeSearch()">
                {{ result.name }}<br/>
                <span style="font-size: 70%;">{{ result.subtext }}</span>
              </li>
            </ul>
          </div>

        </div>
      </transition>
    </div>

    <div id="search-status" v-if="searchComplete">
      <button class="button navbar-action-button is-outlined is-medium search-status" @click="restartSearch()">
        <span class="search-readout">
        {{ searchCompleteName }}
        </span>
        <span class="icon is-small">
          <svg class='icon__cnt'><use xlink:href='#ei-search-icon'/></svg>
        </span>
      </button>
    </div>

  </div>
</template>

<script>
import axios from 'axios';
import Vue from 'vue'

export default {
  data: function () {
    return {
      path: "",
      token: "",
      results: [],
      query: "",
      timer: false,
      selectedIdx: -1,
      displayAutoComplete: false,
      searchInProgress: false,
      searchComplete: true,
      searchFailed: false,
      mode: 'normal'
    }
  },
  created: function () {
    let n = document.getElementById("search-box")
    this.path = n.getAttribute("auto-path")
    this.token = n.getAttribute("token")

    this.eventHub.$on('mark-search-complete', this.markSearchComplete)
    this.eventHub.$on('mark-search-error', this.markSearchError)

    if (n.getAttribute("mode")) this.mode = n.getAttribute("mode")

    let initSearchParams = {}
    if (!(n.getAttribute("init-query") == undefined) && !(n.getAttribute("init-path") == undefined)) {
      this.query = n.getAttribute("init-query")
      if (this.query) {
        initSearchParams = {path: n.getAttribute("init-path")}
        this.updateAutoComplete(initSearchParams)
      }
    }
  },
  mounted: function() {
    window.addEventListener('resize', () => {
      let adjustment = 0
      this.setSearchAutoWidth(0)
    });
  },
  computed: {
    searchCompleteName: function() {
      return "Quick Lookup"
    }
  },
  methods: {
    handleInput: function (event) {
      this.query = event.target.value;

      if (this.query.length > 2) {
          if (this.timer){
            clearTimeout(this.timer);
          }
          this.timer = setTimeout(() => {
            this.searchInProgress = true
            this.updateAutoComplete({})
          }, 200);
      } else if (this.query.length == 0) {
        if (this.timer){
          clearTimeout(this.timer);
        }
        this.timer = setTimeout(() => {
            this.results = []
            this.selectedIdx = 0
            this.displayAutoComplete = false;
        }, 300);
      }
    },
    handleBlur: function (event) {
      this.timer = setTimeout(() => {
        this.selectedIdx = -1;
        this.searchComplete = true;
        this.displayAutoComplete = false;
      }, 100);
    },
    handleFocus: function (event) {
      //this.displayAutoComplete = true;
      this.showAutoCompleteBox()
      this.searchFailed = false;
    },
    handleKeyDown: function (event) {
      if(!this.displayAutoComplete) return;

      let key = event.keyCode;

      const DOWN = 40
      const UP = 38
      const ENTER = 13
      const ESC = 27

      switch (key) {
        case DOWN:
          event.preventDefault()
          if (this.selectedIdx < (this.results.length - 1)) this.selectedIdx++;
        break;
        case UP:
          event.preventDefault()
          if (this.selectedIdx > 0) this.selectedIdx--;
        break;
        case ENTER:
          event.preventDefault()
          this.executeSearch()
          this.displayAutoComplete = false
        break;
        case ESC:
          this.displayAutoComplete = false
        break;
      }
    },
    entryClass: function(idx) {
      if (idx < 0) return ""

      if (this.selectedIdx == idx) {
        return `ac-focused-${this.results[idx].cat}`
      } else {
        return `ac-${this.results[idx].cat}`
      }
    },
    mousemove(i){
      this.selectedIdx = i
    },
    updateAutoComplete: function(params) {

      axios.post(this.path, {
        q: this.query,
        authenticity_token: this.token
      })
      .then((response) => {
        this.results = response.data;
        this.selectedIdx = 0

        /* special case where a specific path is passed....review*/
        if (params.path) {
          let idx = this.results.findIndex(function(o) {
            return o.path == params.path
          })
          this.selectedIdx = idx
        }

        this.searchInProgress = false
        this.showAutoCompleteBox()
      })
      .catch((error) => {
        this.displayAutoComplete = false
      })
    },
    restartSearch: function() {
      this.searchComplete = false
      this.selectedIdx = 0
      Vue.nextTick( () => {
        this.$refs.search.focus()
      })

    },
    hideElementId: function(id) {
      let el = document.getElementById(id)
      if (el) el.style.display = 'none';
    },
    executeSearch: function() {
      let item = this.results[this.selectedIdx]

      /* If it contains surl: single url just go to page.  if graph open, add node.*/
      if (item.surl) {
        window.location = item.surl
        return
      }

      this.searchInProgress = true

      /* When a search is executed, we clear the content to make way for the results */
      //document.getElementById('content-wrapper').innerHTML = ''
      this.hideElementId('content-wrapper')
      this.hideElementId('upgrade-app')

      /* Normal navbar search*/
      this.eventHub.$emit('set-tiles', {
        query: this.query, category: `general-${item.cat}`
      });

      logevent('search', item.path)
      this.searchInProgress = false
      this.searchComplete = true
      this.lastSelectedIdx = this.selectedIdx
    },
    markSearchComplete: function(data) {
      this.searchComplete = true
      this.searchInProgress = false
    },
    markSearchError: function(data) {
      this.searchInProgress = false
      this.searchFailed = true
      this.displayAutoComplete = false
    },
    setSearchAutoWidth: function(adjust = 0) {
      let sinput = document.getElementById("mnsrchinp")
      let sauto = document.getElementById("mnsrchauto")
      let qhelp = document.getElementById("qckhlp")

      if (sauto && sinput) sauto.style.width = `${sinput.clientWidth + adjust}px`;
      if (qhelp && sinput) qhelp.style.width = `${sinput.clientWidth}px`;
    },
    showAutoCompleteBox: function() {
      this.displayAutoComplete = true
      this.setSearchAutoWidth(-50)
    }
  }
}
</script>

<style scoped>
</style>
