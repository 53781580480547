export default {
  applySprinkle() {

    var signinNav = document.getElementById('signin-nav');
    if (signinNav) {
      signinNav.addEventListener('click', function(event) {
        event.preventDefault();
        eventHub.$emit("require-account", {mode: 'signin'});
      });
    }

    var accountUpgrade = document.getElementById('service-level-link');
    if (accountUpgrade) {
      accountUpgrade.addEventListener('click', function(event) {
        event.preventDefault();
        eventHub.$emit("require-upgrade", {});
      });
    }

    var accountUpgradeNav = document.getElementById('service-level-link-nav');
    if (accountUpgradeNav) {
      accountUpgradeNav.addEventListener('click', function(event) {
        event.preventDefault();
        eventHub.$emit("require-upgrade", {});
      });
    }

  }
}
