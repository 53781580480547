<template>
  <div :class="delayClass()" >
    <header class="card-header">
      <p class="card-header-title disease-txt">
        <a :href="url">{{ record.name }}</a>
      </p>
    </header>
    <table class="table card-content card-table">
      <tr>
        <td></td>
      </tr>
    </table>
  </div>
</template>

<script>
import Card from './card';

export default {
  extends: Card,
  computed: {
    url: function () {
      return `/disease/${this.record.id}`
    }
  }
}
</script>
