import { render, staticRenderFns } from "./activity-feed.vue?vue&type=template&id=2a6b274a&scoped=true&"
import script from "./activity-feed.vue?vue&type=script&lang=js&"
export * from "./activity-feed.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a6b274a",
  null
  
)

export default component.exports