<template>
  <div :class="delayClass()" >
    <header class="card-header">
      <p class="card-header-title company-txt">
        <a :href="url" class="company-txt">{{ record.name }}</a>
      </p>
    </header>
    <table class="table card-content card-table">
      <tr>
        <td>Location</td>
        <td>{{record.city}} {{record.state}}</td>
      </tr>
    </table>
  </div>
</template>

<script>
import Card from './card';

export default {
  extends: Card,
  computed: {
    url: function () {
      return `/company/${this.record.id}/${this.record.name.replace(/[^a-zA-Z]|\s/g, '')}`
    },
    graphUrl: function () {
      return `/network/graph/company/${this.record.id}`
    }
  }
}
</script>
