<template>
  <form @submit.prevent="executeSignUp">
    <div class="field">
      <label class="label is-medium">Name</label>
      <p class="control has-icons-left has-icons-right">
        <input class="input is-medium" v-model="name" placeholder="Name" type="text" value="">

        <span class="icon is-small is-left">
          <svg class="icon__cnt"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#ei-user-icon"></use></svg>
        </span>

        <span class="icon is-small is-right has-text-success" v-show="nameValid">
          <svg class="icon__cnt is-large">
            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#ei-check-icon"></use>
          </svg>
        </span>
      </p>
    </div>


    <div class="field">
      <label class="label is-medium">Email</label>
      <p class="control has-icons-left has-icons-right"">
        <input class="input is-medium" v-model="email" type="email" placeholder="Email" @input="checkEmail">

        <span class="icon is-small is-left">
          <svg class="icon__cnt"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#ei-envelope-icon"></use></svg>
        </span>

        <span class="icon is-small is-right has-text-success" v-show="emailValid">
          <svg class="icon__cnt is-large">
            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#ei-check-icon"></use>
          </svg>
        </span>

      </p>
    </div>
    <div class="field">
      <label class="label is-medium">Choose A Password</label>
      <p class="control has-icons-left has-icons-right">
        <input class="input is-medium" type="password" v-model="password" placeholder="Password" >

        <span class="icon is-small is-left">
          <svg class="icon__cnt"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#ei-lock-icon"></use></svg>
        </span>

        <span class="icon is-small is-right has-text-success" v-show="passwordValid">
          <svg class="icon__cnt is-large">
            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#ei-check-icon"></use>
          </svg>
        </span>
      </p>
    </div>

    <nav class="level">
      <!-- Left side -->
      <div class="level-left">
        <div class="level-item">
          <button class="button is-success is-medium">Create Account</button>
        </div>
      </div>

      <!-- Right side -->
      <div class="level-right">
        <p class="level-item">
           <a class="forgot_link" @click="switchToSignIn">already have an account?</a>
        </p>

      </div>
    </nav>

    <div class="has-text-danger">
      {{problemMessage}}
    </div>

  </form>
</template>

<script>
import axios from 'axios';

export default {
  data: function () {
    return {
      createAccountPath: '/account/create',
      checkEmailPath: '/signup/checkemail',
      name: '',
      email: '',
      password: '',
      emailValid: false,
      problemMessage: ''
    }
  },
  props: ['token'],
  computed: {
    nameValid: function() {
      return (this.name.length > 2)
    },
    passwordValid: function() {
      return (this.password.length > 5)
    }
  },
  methods: {
    switchToSignIn: function(event) {
      this.$emit('signinform')
    },
    checkEmail: function(event) {
      axios.post(this.checkEmailPath, {
        email: this.email,
        authenticity_token: this.token
      })
      .then((response) => {
        this.emailValid = response.data.valid
      })
      .catch((error) => {

      })
    },
    executeSignUp: function(event) {
      if (!this.emailValid) {
         this.problemMessage = "Please ensure the email address is valid.  Has this email already registered an account?"
         return ''
      }

      if (!this.nameValid) {
         this.problemMessage = "Please enter your name."
         return ''
      }

      if (!this.passwordValid) {
         this.problemMessage = "Please enter a password at least 6 characters long."
         return ''
      }

      axios.post(this.createAccountPath, {
        le: this.email,
        lp: this.password,
        name: this.name,
        authenticity_token: this.token
      })
      .then((response) => {
        if ( response.data.success ) {
          this.$emit('signupsuccessful', {id: response.data.user_id, name: response.data.name})
        } else {
          this.problemMessage = "That didn't work.  Try again or contact berrydigital@gmail.com.com"
        }
      })
      .catch((error) => {
        this.problemMessage = "That didn't work.  Try again or contact berrydigital@gmail.com.com"
      })
    }
  }

}
</script>
