<template>
  <div id="calendar-app" class="calendar-app" style="position: relative">
    <div v-if="!disasterState" :class="activeEntries.length > 0 ? 'cal-fade' : '' ">

      <svg  class="calendar-svg" width="100%" :viewBox="getViewBoxDimensions()">

        <defs>
          <template v-for="k in patternCombinationsKeys">
            <pattern :id="k" width="1" height="1" viewBox="0 0 1 1" preserveAspectRatio="none">
                <rect v-for="klass, i in patternCombinations.get(k).slice(0, 3)"
                      :x="i / patternCombinations.get(k).length"
                      :width="1 / patternCombinations.get(k).length"
                      height="1"
                      :class="klass"
                />
            </pattern>
          </template>
        </defs>

        <g class="legend">
          <g v-for="(l, i) in legendEntries" :transform="legendEntryTransform(i)" >
            <rect :width="rectUnitSize" :height="rectUnitSize" :class="l + ' month-box'" >
              <title> {{ classNameToTitle(l) }} </title>
            </rect>
            <text :x="rectUnitSize + 10" :y="(rectUnitSize / 2) + 5" class="cal-year-txt">
              {{ classNameToTitle(l) }}
            </text>
          </g>
        </g>

        <g :transform="calendarTranslate()" class="calendar">
          <g class="year-labels">
            <text v-for="(y, i) in years" x="0" :y="(i * rectUnitSize) + 18" font-size="18" class="cal-year-txt">
              {{y}}
            </text>
          </g>
          <g class="calendar-main">
            <rect v-for="(mo, i) in months"
                  :mo="mo"
                  :x="60 + monthX(mo)"
                  :y="monthY(mo)"
                  :width="rectUnitSize"
                  :height="rectUnitSize"
                  class="month-box"
                  :fill="patternFillStr(mo)"
                  @click="handleMonthClick(mo)"
             >
              <title>{{monthName(mo)}} {{ mo.getFullYear() }}</title>
            </rect>

          </g>
        </g>
      </svg>

      <div v-if="false" v-show="!showTable && entries.length > 0 && !disableTableView" class="more-search">
        <button @click="toggleTable" class="button is-large is-primary is-outlined">
          View Data in Table
        </button>
      </div>

      <div v-if="showTable && entries.length > 0">
        <table  class="table">
          <tr v-for="e in entries">
            <td>{{ e.event_date }}</td>
            <td >
              <div>
                <a v-if="e.path" :href="e.path">{{ e.content }}</a>
                <span v-else>{{ e.content }}</span>
              </div>
              <span v-for="d in e.diseases">
                <a style="margin: 5px 5px 5px 0px" class="is-medium tag is-disease" @click.prevent="addToGraph('disease', d.id)">{{ d.name }}</a>
              </span>
            </td>
          </tr>
        </table>
      </div>
    </div>

    <div v-if="activeEntries.length > 0" class="calendar-month-details">
      <transition appear
              name="custom-classes-transition"
              enter-active-class="animated bounceInRight"
              leave-active-class="animated bounceOutRight">
        <div class="box active-box" :style="activeContainerStyle()">

          <div class="icon active-icon" style="height: 40px; width: 40px;" @click="clearActiveEntries()">
            <svg class="icon__cnt"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#ei-close-o-icon"></use></svg>
          </div>

          <div class="is-size-5">{{ monthName(activeMonth) }} {{ activeMonth.getFullYear() }}</div>
          <table class="table cal-detail">
            <thead>
              <tr>
                <td></td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="e in activeEntries">
                <td>{{ e.event_date }}</td>
                <td >
                  <div v-if="e.content_title">
                    <b>{{ e.content_title }}</b>
                  </div>
                  <div>
                    <a v-if="e.path" :href="e.path">{{ e.content }}</a>
                    <span v-else>{{ e.content }}</span>
                  </div>
                  <span v-for="d in e.diseases" style="margin: 5px 5px 5px 0px" class="is-medium tag is-disease">
                    {{ d.name }}
                  </span>
                </td>
              </tr>
            </tbody>

          </table>
        </div>
      </transition>
    </div>

    <div v-if="disasterState" style="text-align: center">
      <div class="icon has-text-danger" style="height: 40px; width: 40px;">
        <svg class="icon__cnt"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#ei-exclamation-icon"></use></svg>
      </div><br/>
      No timeline data available.
    </div>

    <div v-show="loading">
      <div class="loader big-loader more-search" ></div>
    </div>

  </div>
</template>

<script>
import axios from 'axios'

export default {
  props: [
    'propPath',
    'propAssetId',
    'propAssetType',
    'disableTableView'
  ],
  data: function () {
    return {
      months: [],
      years: [],
      legendEntries: [],
      entries: [],
      monthsToEntries: (new Map()),
      activeEntries: [],
      activeMonth: '',
      path: '',
      token: '',
      assetId: '',
      assetType: '',
      rectUnitSize: 30,
      showToday: true,
      disasterState: false,
      patternCombinations: [],
      patternCombinationsKeys: [],
      showTable: false,
      loading: false
    }
  },
  created: function () {
    /* This can be instantiated by the presence of html values, in which case the props are empty
     *   So we extract the props from the html.  Complicated.  Otherwise we use the props.  This is just
     *   wrong and begs for refactor.
     */
    if (typeof this.propPath === 'undefined') {
      let n = document.getElementById("calendar-app")
      this.path = n.getAttribute("path")
      this.token = n.getAttribute("token")
      this.assetId = n.getAttribute("asset-id")
      this.assetType = n.getAttribute("asset-type")
    } else {
      this.path = this.propPath
      this.assetId = this.propAssetId
      this.assetType = this.propAssetType
    }

    this.reloadData()
  },
  watch: {
    propAssetId: function(val) {
      this.disasterState = false
      this.path = this.propPath
      this.assetId = this.propAssetId
      this.assetType = this.propAssetType
      this.activeEntries = []
      this.months = []
      this.reloadData()
    }
  },
  methods: {
    reloadData: function() {
      this.loading = true

      axios.post(this.path, {
        id: this.assetId,
        authenticity_token: this.token
      })
      .then((response) => {
        this.generateEntries(response.data)
        this.loading = false
      })
      .catch((error) => {
        this.loading = false
        this.disasterState = true
      })
    },
    generateEntries: function(data) {

      /* This method processes the data payload to create the underlying data properties to drive the calendar
       *    We start by checking if there is no data to display */
      this.entries = data.entries
      if (this.entries.length == 0) {
        this.disasterState = true
        return null
      }

      /* Now we prep the year and months, and a lookup table from months -> events */
      let firstEvent = new Date(data.first_event)
      let lastEvent = new Date(data.last_event)

      this.years = []
      this.monthsToEntries = new Map()

      if (!(firstEvent instanceof Date)) {
        this.months = []
        return true
      }

      let firstEventMonth = new Date(firstEvent.getFullYear(), firstEvent.getMonth(), 1)
      let firstCalendarMonth = this.addMonths(firstEventMonth, -6)

      let lastEventMonth = new Date(lastEvent.getFullYear(), lastEvent.getMonth(), 1)
      let lastCalendarMonth = this.addMonths(lastEventMonth, 6)

      this.months = [...Array(this.monthDiff(firstCalendarMonth, lastCalendarMonth)).keys()].map(i => this.addMonths(firstCalendarMonth, i))

      /* Create a set of years to iterate over*/
      let yearSet = new Set()
      for (let mo of this.months) {
        yearSet.add(mo.getFullYear())
      }
      this.years = Array.from(yearSet)

      /* Populate a map from month to entries to look up display classes and info.
       *   And while iterating, collect a set of distinct entry types for the legend.
      */
      let legendSet = new Set()
      for (let e of this.entries) {
        let d = new Date(e.event_date)
        let m = new Date(d.getFullYear(), d.getMonth(), 1)

        legendSet.add(e.class_name)

        if (this.monthsToEntries.has(m.toString())) {
          this.monthsToEntries.get(m.toString()).push(e)
        } else {
          this.monthsToEntries.set(m.toString(), [e])
        }
      }

      this.legendEntries = Array.from(legendSet)
      this.legendEntries.sort()

      /* Finally we iterate over the months to generate pattern-combos for those months
       *   that include multiple entries.
       */

      let pcombos = new Map()
      for (let m of this.months) {
        let combo = this.monthToPatternCombo(m)

        if (combo.length > 0) {
          pcombos.set(combo.join("-"), combo)
        }
      }
      this.patternCombinations = pcombos
      this.patternCombinationsKeys = Array.from(pcombos.keys())
    },
    clearActiveEntries: function() {
      this.activeEntries = []
    },
    monthToPatternCombo: function (m) {
      if (!this.monthsToEntries.has(m.toString())) return []

      let es = this.monthsToEntries.get(m.toString())
      return Array.from(new Set(es.map(x => x.class_name))).sort()
    },
    patternFillStr: function (mo) {
      let combo = this.monthToPatternCombo(mo)
      if (combo.length < 1) return "none"
      return `url(#${combo.join('-')})`
    },
    addMonths: function (date, months) {
      let d = new Date(date.getTime());
      d.setMonth(date.getMonth() + months);
      return d;
    },
    monthX: function (mo) {
      return (mo.getMonth() % 12) * this.rectUnitSize
    },
    monthY: function (mo) {
      let firstMonth = this.months[0]
      let moYearDiff = mo.getYear() - firstMonth.getYear()
      return moYearDiff * this.rectUnitSize
    },
    activeContainerStyle: function() {
      /* returns a style attribute entry for the active items box to position it close to active month*/
      let topOffset = this.legendHeight() + this.monthY(this.activeMonth) + 20
      return `top: ${topOffset}px`
    },
    eventMonthClass: function(mo) {
      let classNames = ["month-box"]

      /* Check if this month is today and we want to display that. */
      if (this.showToday && mo.getMonth() == (new Date).getMonth() && mo.getYear() == (new Date).getYear()) {
        classNames.push(["month-today"])
      }

      let entries = this.monthsToEntries.get(mo.toString())

      if(entries && entries.length > 0) classNames.push(entries[0].class_name)

      return classNames.join(" ")
    },
    classNameToTitle: function(klass) {
      return klass.split(/-/).map(x => x.charAt(0).toUpperCase() + x.slice(1)).join(" ")
    },
    legendEntryTransform: function(i) {
      let x = (i%2) * 220
      let y = ( parseInt(i / 2) * this.rectUnitSize) + (10 * parseInt(i / 2))
      return `translate(${x}, ${y})`
    },
    getViewBoxDimensions: function() {
      let xWidth = this.rectUnitSize * 15
      let yHeight = (this.years.length * this.rectUnitSize * 1.1) + this.legendHeight()
      return `0 0 ${xWidth} ${yHeight}`
    },
    legendHeight: function() {
      return ((this.legendEntries.length / 2) * this.rectUnitSize * 1.5) + this.legendMarginBottom()
    },
    legendMarginBottom: function() {
      return 1 * this.rectUnitSize;
    },
    calendarTranslate: function() {
      return `translate(0, ${this.legendHeight()})`
    },
    monthName: function(mo) {
      let monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
      return monthNames[mo.getMonth()]
    },
    handleMonthClick: function(mo) {
      logevent('calendar-month-click', this.assetType)

      /* This month has no entry, so we do nothing */
      if (!this.monthsToEntries.has(mo.toString())) {
        this.activeEntries = []
        return null
      }

      let entries = this.monthsToEntries.get(mo.toString())

      this.activeEntries = entries
      this.activeMonth = mo
    },
    monthDiff: function(a, b) {
      let months = (b.getFullYear() - a.getFullYear()) * 12
      months -= a.getMonth() + 1
      months += b.getMonth()
      months = (months <= 0 ? 0 : months)
      return months
    },
    addToGraph: function(eType, eId) {
      //this.clearActiveEntries()
      eventHub.$emit(
        "graph-add",
        { entityType: eType,
          entityId: eId,
          restriction: 'init',
          subsearch: true,
          centerOn: true,
        });
    },
    toggleTable: function(event) {
      this.showTable = !this.showTable
    }

  }
}
</script>

<style scoped>
</style>
