<script>


export default {
  props: [
    'record',
    'idx',
    'graphButtonType',
    'subsearchfromtype'
  ],
  methods: {
    delayClass: function () {
      if (this.idx == 0) return 'ade-zero';
      if (this.idx == 1) return 'ade-one';
      if (this.idx == 2) return 'ade-two';
      if (this.idx == 3) return 'ade-three';
      if (this.idx == 4) return 'ade-four';
      if (this.idx == 5) return 'ade-five';
      if (this.idx == 6) return 'ade-six';
      if (this.idx == 7) return 'ade-seven';
      if (this.idx == 8) return 'ade-eight';
      if (this.idx == 9) return 'ade-nine';
      return 'ad-zero';
    },
    showDetailsButton: function() {
      return (typeof this.graphButtonType == 'undefined' || this.graphButtonType != 'connect')
    },
    showNewGraphButton: function() {
      if (this.graphButtonType == 'connect') return false
      if (typeof graphActive != 'undefined' && graphActive) return false
      return true
    },
    showAddGraphButton: function() {
      if (this.graphButtonType == 'connect') return false
      if (typeof graphActive != 'undefined' && graphActive) return true
      return false
    },
    addToGraph: function() {
      this.$emit('remove-card', this.record)

      eventHub.$emit(
        "graph-add",
        { entityType: this.record.category,
          entityId: this.record.id,
          restriction: 'init',
          centerOn: true,
          subsearch: true
        });
    },
    connectToGraph: function(restrictions) {
      eventHub.$emit("graph-connect", {
        entityType: this.record.category,
        entityId: this.record.id,
        restrictions: restrictions
      });
    }
  }
}
</script>
