<template>
  <div id="upgrade-app"  v-if="displayActive" style="width: 100%;">
    <section class="section">
      <transition appear
              name="custom-classes-transition"
              enter-active-class="animated bounceInRight"
              leave-active-class="animated bounceOutRight">

        <div class="container">

          <div v-if="!showSuccess">
            <div class="columns">
              <div class="column is-narrow">
                <div class="tabs is-medium">
                  <ul>
                    <li class="is-active"><a @click="showMonitoring">Change Plan</a></li>
                  </ul>
                </div>
              </div>
            </div>

            <div>
              <UpgradeForm :token="token" :stripetoken="stripeToken" v-on:monitoringupgradesuccess="monitoringUpgradeSuccess" v-on:monitoringdowngradesuccess="monitoringDowngradeSuccess"/>
            </div>
          </div>

          <div v-if="showSuccess">
            <SubscriptionChangeSuccessMessage :successPlan="successPlan" v-on:closesuccess="closeSuccess" />
          </div>


          <div class="columns">
            <div class="column is-6">
              <a class="button is-outlined is-default" @click.prevent="clearDisplay">No Thanks, Go Back</a>
            </div>
            <div class="column is-4"></div>
          </div>

        </div>
      </transition>
    </section>
  </div>
</template>

<script>
import UpgradeForm from 'components/upgrade/upgrade_form.vue'
import SubscriptionChangeSuccessMessage from 'components/upgrade/subscription_change_success.vue'

export default {
  data: function () {
    return {
      token: '',
      displayActive: false,
      mode: '',
      activeForm: '',
      showSuccess: false,
      successMessage: '',
      stripeToken: '',
      restoreDivId: '' //One of the divs can be set back to display on cancel
    }
  },
  components: {
    UpgradeForm,
    SubscriptionChangeSuccessMessage
  },
  created: function () {
    let n = document.getElementById("upgrade-required-app")
    this.token = n.getAttribute("token")
    this.stripeToken = n.getAttribute("stripe-public")

    let initMode = n.getAttribute("trigger-mode")
    // you are here.
    this.eventHub.$on('require-upgrade', this.promptUser)
    this.eventHub.$on('clear-all', this.clearDisplay)
  },
  methods: {
    showResearch: function() {
      this.activeForm = 'research'
    },
    showMonitoring: function() {
      this.activeForm = 'monitoring'
    },
    promptUser: function (event) {
      logevent('upgrade-required', event.mode)

      window.location.hash = 'upgrade'

      this.restoreDivId = 'content-wrapper'

      document.getElementById('content-wrapper').style.display = 'none'

      /* Dark art of back button manipulation */
      if (!window.location.toString().match("trigger")) {
        history.replaceState( {clearable: true} , window.title, window.location );
      }

      /* Are we displaying monitoring or research feature benefits or just sign in, etc? */

      this.mode = 'monitoring'
      if (event.mode) this.mode = event.mode
      if (this.mode == 'research') {
        this.activeForm = 'research'
      } else if (this.mode == 'monitoring') {
        this.activeForm = 'monitoring'
      }

      this.displayActive = true
    },
    researchUpgradeSuccess: function(event) {
      this.showSuccess = true
      this.successPlan = 'Research Pro'
      logevent('upgrade-success', 'research')
    },
    monitoringUpgradeSuccess: function(event) {
      this.showSuccess = true
      this.successPlan = 'Monitoring Pro'
      logevent('upgrade-success', 'monitoring')
    },
    researchDowngradeSuccess: function(event) {
      this.showSuccess = true
      this.successPlan = 'Free'
      logevent('downgrade', 'research')
    },
    monitoringDowngradeSuccess: function(event) {
      this.showSuccess = true
      this.successPlan = 'Free'
      logevent('downgrade', 'monitoring')
    },
    closeSuccess: function(event) {
      this.showSuccess = false
      /* End the whole log-in/sign-up process by returning the user to whatever they were doing.*/
      this.displayActive = false

      /* Restore the content wrapper */
      let n = document.getElementById('content-wrapper')
      n.style.opacity = 0
      n.style.display = 'block'
      n.className += " fadeIn animated ade-six"
    },
    clearDisplay: function() {
      /* End the whole log-in/sign-up process by returning the user to whatever they were doing.*/
      this.displayActive = false

      /* Restore the content wrapper */
      let n = document.getElementById('content-wrapper')
      if (this.restoreDivId != '') {
        n = document.getElementById(this.restoreDivId)
      }
      n.style.opacity = 0
      n.style.display = 'block'
      n.className += " fadeIn animated ade-six"
    }


  }
}
</script>

<style scoped>
</style>
