export default {

  applySprinkle() {
    var nodes = document.querySelectorAll('.link-label-button');
    for (var i = 0; i < nodes.length; i++) {
      nodes[i].addEventListener('click', function(event) {
        event.preventDefault();

        var xhr = new XMLHttpRequest();

        xhr.open('POST', event.target.getAttribute("href"), true);
        xhr.setRequestHeader('Content-Type', 'application/json');

        let token = document.getElementById("auth_token").getAttribute("token")

        xhr.setRequestHeader('X-CSRF-Token', token);

        xhr.send(JSON.stringify({
          'id': event.target.getAttribute("data-company-id"),
          'name': event.target.getAttribute("data-name"),
          'linkaction':  event.target.getAttribute("data-action"),
          'item_type': event.target.getAttribute("data-item-type"),
          'item_id': event.target.getAttribute("data-item-id"),
          'authenticity_token': token
        }));

        if ( event.target.getAttribute("data-refresh") == "YES") {
          // refresh the page without querystring
          window.location.href = window.location.href.split('?')[0];
        }

        event.target.parentNode.parentNode.style.display = 'none';
      })
    }


    var nodes = document.querySelectorAll('.classify-label-button');
    for (var i = 0; i < nodes.length; i++) {
      nodes[i].addEventListener('click', function(event) {
        event.preventDefault();

        var xhr = new XMLHttpRequest();

        xhr.open('POST', event.target.getAttribute("href"), true);
        xhr.setRequestHeader('Content-Type', 'application/json');

        let token = document.getElementById("auth_token").getAttribute("token")

        xhr.setRequestHeader('X-CSRF-Token', token);

        xhr.send(JSON.stringify({
          'id': $(this).attr("data-id"),
          'biotech': $(this).attr("data-biotech"),
          'authenticity_token':  $("#auth_token").attr("token")
        }));

        if ( event.target.getAttribute("data-refresh") == "YES") {
          // refresh the page without querystring
          window.location.href = window.location.href.split('?')[0];
        }

        event.target.parentNode.parentNode.style.display = 'none';
      })
    }
  }
}