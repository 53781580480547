<template>

  <div class="columns" >
    <div class="column is-12">
      <div v-show="!disasterState && loading" style="text-align: center">
       <div class="loader big-loader" ></div>
      </div>

      <div v-if="!disasterState">
        <div v-if="feedItems.length > 0">
          <div v-for="(result, j) in feedItems" class="feed-item">
            <div class="">
              <transition appear name="custom-classes-transition"
                          enter-active-class="animated bounceInRight"
                          leave-active-class="animated bounceOutRight">
                <FeedItem :record="result" :idx="j" />
              </transition>
            </div>
          </div>

          <div v-if="allFeedItems.length > feedItems.length" class="more-search">
            <button @click="requestMoreResults" class="button is-normal is-outlined more-search">
              More Results
            </button>
          </div>
        </div>
        <div v-else-if="!loading" style="text-align: center">
          <div class="icon has-text-info" style="height: 40px; width: 40px;">
            <svg class="icon__cnt"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#ei-minus-icon"></use></svg>
          </div><br/>
          No matching data found.
        </div>
      </div>
      <div v-else style="text-align: center">
        <div class="icon has-text-danger" style="height: 40px; width: 40px;">
          <svg class="icon__cnt"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#ei-exclamation-icon"></use></svg>
        </div><br/>
        Feed service is not available at this time.  Please try later.
      </div>
    </div>

  </div>
</template>

<script>
import axios from 'axios'

import FeedItem from 'components/activities/feed_item'


export default {
  props: [
    'token',
    'path',
    'mode'
  ],
  data: function () {
    return {
      feedItems: [],
      allFeedItems: [],
      disasterState: false,
      loading: true
    }
  },
  created: function () {
    this.loadFeedItems(null)
  },
  components: {
    FeedItem
  },
  methods: {
    loadFeedItems: function(lastId) {
      axios.post(this.path, { authenticity_token: this.token, mode: this.mode })
      .then((response) => {
        // this.feedItems.concat(response.data.feed_items)  // dynamic request for mores
        this.allFeedItems = response.data.feed_items
        this.feedItems = response.data.feed_items.slice(0, 10)
        this.loading = false
      })
      .catch((error) => {
        this.disasterState = true
      })
    },
    clearResultSet: function (event) {
      this.feedItems = []
    },
    requestMoreResults: function (event) {
      let lastDisplayedItem = this.feedItems[this.feedItems.length - 1]
      let indexFrom = this.allFeedItems.indexOf(lastDisplayedItem)

      this.feedItems = this.allFeedItems.slice(0, indexFrom + 10)
      // this.loadFeedItems(this.feedItems[this.feedItems.length - 1].id) // dynamic request for more
    },
    removeCard: function(event) {
      this.feedItems.splice(event.idx, 1);
    }
  }
}
</script>

<style scoped>
</style>
