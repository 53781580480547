<template>
  <div>
    <div v-show="isLoading">
      <div class="loader search-loader" ></div>
    </div>
    <div v-show="!isLoading">
      <form @submit.prevent="executeForgot" v-show="!forgotComplete">
        <div class="field">
          <label class="label is-medium">Enter the email address associated with your account</label>
          <p class="control">
            <input class="input is-medium" type="email" v-model="email" placeholder="Email">
          </p>
        </div>

        <nav class="level">
          <!-- Left side -->
          <div class="level-left">
            <div class="level-item">
              <button class="button is-success is-medium">Request Reset Password Email</button>
            </div>
          </div>
        </nav>
        <div class="has-text-danger">
          {{problemMessage}}
        </div>
      </form>
      <div v-show="forgotComplete" class="columns is-text-centered">
        <div class="column is-narrow">
          <span class="icon is-small is-left has-text-success" style="height: 100px; width: 100px;">
            <svg class="icon__cnt"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#ei-check-icon"></use></svg>
          </span>
        </div>
        <div class="column">
          Please check your email for a link to complete the process of resetting your password.<br/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  data: function () {
    return {
      forgotPath: '/account/forgot',
      email: '',
      password: '',
      problemMessage: '',
      forgotComplete: false,
      isLoading: false
    }
  },
  props: ['token'],
  methods: {
    executeForgot: function(event) {
      this.problemMessage = ""
      this.isLoading = true

      axios.post(this.forgotPath, {
        le: this.email,
        lp: this.password,
        authenticity_token: this.token
      })
      .then((response) => {
        this.isLoading = false
        if ( response.data.success ) {
          this.forgotComplete = true
        } else {
          this.problemMessage = "That didn't work.  Is this the email associated with your account?  Try again or contact berrydigital@gmail.com.com"
        }
      })
      .catch((error) => {
        this.isLoading = false
        this.problemMessage = "That didn't work.  Is this the email associated with your account?  Try again or contact berrydigital@gmail.com.com"
      })
    },
    forgotPassword: function(event) {
      this.$emit('forgot', {})
    },
    forgotProcessComplete: function() {
      this.$emit('forgotsuccessful', {})
    }
  }
}
</script>
