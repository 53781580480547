<template>
  <div :class="delayClass()" >
    <header class="card-header">
      <p class="card-header-title inventor-txt">
        {{ record.name }}
      </p>
    </header>
    <table class="table card-content card-table">
      <tr>
        <td>Location</td>
        <td>{{record.city}} {{record.state}}</td>
      </tr>
    </table>
  </div>
</template>

<script>
import Card from './card';

export default {
  extends: Card,
  computed: {
    url: function () {
      return `/inventor/${this.record.id}/${this.record.url_name}`
    },
    graphUrl: function() {
      return `/network/graph/inventor/${this.record.id}/${this.record.url_name}`
    }
  }
}
</script>
