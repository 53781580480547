<template>
  <form @submit.prevent="executeSignIn">
    <div class="field">
      <label class="label is-medium">Email</label>
      <p class="control has-icons-left">
        <input class="input is-medium" type="email" v-model="email" placeholder="Email">
        <span class="icon is-small is-left">
          <svg class="icon__cnt"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#ei-envelope-icon"></use></svg>
        </span>
      </p>
    </div>
    <div class="field">
      <label class="label is-medium">Password</label>
      <p class="control has-icons-left">
        <input class="input is-medium" v-model="password" type="password" placeholder="Password">
        <span class="icon is-small is-left">
          <svg class="icon__cnt"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#ei-lock-icon"></use></svg>
        </span>
      </p>
    </div>

    <nav class="level">
      <!-- Left side -->
      <div class="level-left">
        <div class="level-item">
          <button class="button is-success is-medium">Sign In</button>
        </div>
      </div>

      <!-- Right side -->
      <div class="level-right">
        <p class="level-item">
           <a class="forgot_link" @click="forgotPassword">Forgot your password?</a>
        </p>

      </div>
    </nav>
    <div class="has-text-danger">
      {{problemMessage}}
    </div>
  </form>
</template>

<script>
import axios from 'axios'
export default {
  data: function () {
    return {
      email: '',
      password: '',
      problemMessage: ''
    }
  },
  props: ['token'],
  methods: {
    switchToSignUp: function(event) {
      this.$emit('signupform')
    },
    executeSignIn: function(event) {
      this.problemMessage = ""
      axios.post('/account/signin', {
        le: this.email,
        lp: this.password,
        authenticity_token: this.token
      })
      .then((response) => {
        if ( response.data.success ) {
          this.$emit('signinsuccessful', {id: response.data.user_id, name: response.data.name})
        } else {
          this.problemMessage = "That didn't work.  Are your credentials correct?  Try again or contact berrydigital@gmail.com.com"
        }
      })
      .catch((error) => {
        this.problemMessage = "That didn't work.  Are your credentials correct?  Try again or contact berrydigital@gmail.com.com"
      })
    },
    forgotPassword: function(event) {
      this.$emit('forgot', {})
    }
  }
}
</script>
