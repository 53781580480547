<template>

  <table class="table plan-table is-fullwidth">
    <thead>
      <tr>
        <th></th>
        <th>Events Monitored</th>
        <th class="cent">
          Free
        </th>
        <th class="cent">
          Pro ( $30 / mo )
        </th>
      </tr>
    </thead>
    <tr>
      <td>Applications</td>
      <td>
        <ul>
          <li>Issuance</li>
          <li>Abandonment<li>
          <li>Assignments</li>
        </ul>
      </td>
      <td  class="cent">
        <div class="icon plan-icon limited-serv">
          <svg class="icon__cnt"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#ei-check-icon"></use></svg>
        </div>
        <div class="plan-subtext">
           10
        </div>
      </td>
      <td  class="cent">
        <div class="icon plan-icon has-text-success">
          <svg class="icon__cnt"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#ei-check-icon"></use></svg>
        </div>
        <div class="plan-subtext">
           unlimited
        </div>
      </td>
    </tr>
    <tr>
      <td>Companies</td>
      <td>
        <ul>
          <li>Application Publication</li>
          <li>Assignments</li>
        </ul>
      </td>
      <td class="cent">
        <div class="icon plan-icon limited-serv">
          <svg class="icon__cnt"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#ei-check-icon"></use></svg>
        </div>
        <div class="plan-subtext">
           2
        </div>
      </td>
      <td class="cent">
        <div class="icon plan-icon has-text-success">
          <svg class="icon__cnt"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#ei-check-icon"></use></svg>
        </div>
        <div class="plan-subtext">
           50
        </div>
      </td>
    </tr>

  </table>

</template>

<script>
export default {}
</script>
