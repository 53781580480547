<template>
  <div class="sub-panel search-box">
    <nav class="panel">
      <SubPanelHeader :record="record"
                      :category="record.entityType"
                      :label="subSearchLabel"
                      v-on:hidesubpanel="closePanel"
      >
      </SubPanelHeader>
      <div class="panel-block">
        <div id="subsearch-app" class="search-app">

          <div id="search-interface" v-show="!searchComplete && showSearchBox()">
            <div class="field has-addons has-addons-centered search-field">
              <p class="control is-expanded has-icons-left has-icons-right">
                <input
                  class="input main-search is-large"
                  @input="handleInput"
                  @blur="handleBlur"
                  @focus="handleFocus"
                  @keydown="handleKeyDown"
                  autocomplete="off"
                  type="text"
                  v-model.trim="query"
                  ref="search"
                  placeholder="Search relative to this entity ..."
                 />

                 <span class="icon is-right" v-show="loading">
                   <div class="loader search-loader" ></div>
                 </span>

                 <span class="icon is-small is-right has-text-danger" style="" v-show="searchFailed">
                   <svg class="icon__cnt is-large">
                     <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#ei-exclamation-icon"></use>
                   </svg>
                 </span>

                 <span class="icon is-small is-left">
                   <svg class='icon__cnt'><use xlink:href='#ei-search-icon'/></svg>
                 </span>
              </p>

              <span class="faux-button" v-show="autoCompleteOptions().length && !searchFailed && query.length > 0 && !searchInProgress" @click="executeSearch(false)">
               <div class="icon--ei-arrow-right icon--m pulse-css">
                 <svg class="icon__cnt  pulse-success-icon">
                   <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#ei-arrow-right-icon"></use>
                 </svg>
               </div>
              </span>

            </div>

            <transition name="fade">
              <div class="autocomplete-data" style="max-width: 490px;" v-show="displayAutoComplete && autoCompleteOptions().length > 0">
                <ul>
                  <li v-for="(result, i) in autoCompleteOptions()" :class="entryClass(i)" @mousemove="mousemove(i)" @click="executeSearch(false)">
                    {{ result.name }}<br/>
                    <span style="font-size: 70%;">{{ result.subtext }}</span>
                  </li>
                </ul>

              </div>
            </transition>
          </div>

        </div>
      </div>


      <div class="panel-block">
        <div v-show="loading || results.length > 0 || displayDisasterMessage || showSearchEndMessage" class="container tile-list-container" >
            <div v-for="(result, j) in results" class="tile is-parent" style="padding: 10px;" >
              <div class="tile is-child">

                <transition name="fade">
                  <InventorCard :record="result" :idx="j" :graphButtonType="gbutType()" v-if="result.category == 'inventor'" :subsearchfromtype="record.entityType" class="card search-card" v-on:remove-card="removeCard"></InventorCard>
                  <CompanyCard :record="result" :idx="j" :graphButtonType="gbutType()" v-if="result.category == 'company'" :subsearchfromtype="record.entityType" class="card search-card" v-on:remove-card="removeCard"></CompanyCard>
                  <ApplicationCard :record="result" :idx="j" :graphButtonType="gbutType()" v-if="result.category == 'application'" :subsearchfromtype="record.entityType" class="card search-card" v-on:remove-card="removeCard"></ApplicationCard>
                  <DiseaseCard :record="result" :idx="j" :graphButtonType="gbutType()" v-if="result.category == 'disease'" :subsearchfromtype="record.entityType" class="card search-card" v-on:remove-card="removeCard"></DiseaseCard>
                  <TargetCard :record="result" :idx="j" :graphButtonType="gbutType()" v-if="result.category == 'target'" :subsearchfromtype="record.entityType" class="card search-card" v-on:remove-card="removeCard"></TargetCard>
                </transition>
              </div>
            </div>

          <div v-if="moreAvailable && results.length > 0 && !displayDisasterMessage" class="more-search">
            <button @click="executeSearch(true)" class="button is-large is-primary is-outlined more-search" v-show="!loading">
              More Results
            </button>
          </div>

          <div v-if="!moreAvailable && showSearchEndMessage" class="more-search">
            <div class="notification is-primary more-search">
              Could not find any suitable matches.
            </div>
          </div>

          <div v-show="loading">
            <div class="loader big-loader more-search" ></div>
          </div>

          <div v-show="displayDisasterMessage" style="text-align: center">
            <div class="icon has-text-danger" style="height: 40px; width: 40px;">
                <svg class="icon__cnt"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#ei-exclamation-icon"></use></svg>
              </div><br/>
              No data available.
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import SubPanel from './subpanel';

import axios from 'axios'
import InventorCard from 'components/tiles/card_inventor'
import CompanyCard from 'components/tiles/card_company'
import ApplicationCard from 'components/tiles/card_application'
import DiseaseCard from 'components/tiles/card_disease'
import TargetCard from 'components/tiles/card_target'

export default {
  extends: SubPanel,
  data: function () {
    return {
      loading: false,
      query: "",
      results: [],
      selectedIdx: 0,
      displayDisasterMessage: false,
      displayAutoComplete: false,
      searchInProgress: false,
      searchComplete: false,
      searchFailed: false,
      moreAvailable: false,
      lowRank: '',
      highRank: '',
      lowId: '',
      highId: '',
      showSearchEndMessage: false,
    }
  },
  components: {
    InventorCard,
    CompanyCard,
    ApplicationCard,
    DiseaseCard,
    TargetCard,
  },
  computed: {
    subSearchLabel: function() {
      if (this.mode) return `Search related ${this.mode}`
      return 'Contextual search'
    }
  },
  mounted: function () {
    // this.executeSearch(false)
    // this.displayAutoComplete = false
  },
  methods: {
    autoCompleteOptions: function() {
      let opts = []
      /* The type of subsearch limits the type of drop downs shown.*/
      if (this.record.entityType == 'company' && this.mode == 'applications') {
        opts = [
          {name: 'Applications', subtext: "this company's applications", score: 0, path: '/search/company-application'},
        ]
      } else if (this.record.entityType == 'company' && this.mode == 'contacts') {
        opts = [
          {name: 'Companies', subtext: "companies with related activity", score: 0, path: '/search/company-contacts'},
        ]
      } else if (this.record.entityType == 'application' && this.mode == 'family') {
        opts = [
          {name: 'Aplication Family', subtext: "search applications in family", score: 0, path: '/search/application-family'}
        ]
      } else if (this.record.entityType == 'application' && this.mode == 'diseases') {
        opts = [
          {name: 'Diseases', subtext: "search related diseases", score: 0, path: '/search/application-disease'}
        ]
      } else if (this.record.entityType == 'application' && this.mode == 'targets') {
        opts = [
          {name: 'Targets', subtext: "search related targets", score: 0, path: '/search/application-target'}
        ]
      } else if (this.record.entityType == 'application' && this.mode == 'similars') {
        opts = [
          {name: 'Similar Assets', subtext: "", score: 0, path: '/search/application-similar-assets'}
        ]
      } else if (this.record.entityType == 'inventor' && this.mode == 'applications') {
        opts = [
          {name: 'Applications', subtext: "search prosecuted applications", score: 0, path: '/search/inventor-application'}
        ]
      } else if (this.record.entityType == 'target' && this.mode == 'diseases') {
        opts = [
          {name: 'Diseases', subtext: "search diseases by name", score: 0, path: '/search/target-disease'},
        ]
      } else if (this.record.entityType == 'target' && this.mode == 'applications') {
        opts = [
          {name: 'Applications', subtext: "search applications associated with this target", score: 0, path: '/search/target-application'},
        ]
      } else if (this.record.entityType == 'disease' && this.mode == 'targets') {
        opts = [
          {name: 'Targets', subtext: "search targets by name", score: 0, path: '/search/disease-target'},
        ]
      } else if (this.record.entityType == 'disease' && this.mode == 'applications') {
        opts = [
          {name: 'Applications', subtext: "search applications", score: 0, path: '/search/disease-application'},
        ]
      }
      return opts
    },
    handleInput: function (event) {
      this.query = event.target.value
      this.displayAutoComplete = true
      //Switch selected order
    },
    handleBlur: function (event) {
      this.displayAutoComplete = false
    },
    handleFocus: function (event) {
      this.displayAutoComplete = true
      this.searchFailed = false
    },
    mousemove(i){
      this.selectedIdx = i
    },
    handleKeyDown: function (event) {
      if(!this.displayAutoComplete) return;

      let key = event.keyCode;

      const DOWN = 40
      const UP = 38
      const ENTER = 13
      const ESC = 27

      switch (key) {
        case DOWN:
          event.preventDefault()
          if (this.selectedIdx < (this.autoCompleteOptions().length - 1)) this.selectedIdx++;
        break;
        case UP:
          event.preventDefault()
          if (this.selectedIdx > 0) this.selectedIdx--;
        break;
        case ENTER:
          event.preventDefault()
          this.executeSearch(false)
          this.displayAutoComplete = false
        break;
        case ESC:
          this.displayAutoComplete = false
        break;
      }
    },
    executeSearch: function(isMore) {
      if (!isMore) this.clearResultSet()

      let item = this.autoCompleteOptions()[this.selectedIdx]

      /* Some display state housekeeping */
      this.searchInProgress = true
      this.displayAutoComplete = false;
      this.loading = true
      this.displayDisasterMessage = false
      logevent('subsearch', item.path)

      axios.post(item.path, {
        q: this.query,
        eid: this.record.entityId,
        lr: this.lowRank,
        hr: this.highRank,
        li: this.lowId,
        hi: this.highId
      })
      .then((response) => {
        //this.$refs.search.blur()

        /* Here we check for account required stuff. */
        if (!this.hasAccess(response, 'research')) return null

        this.loading = false
        this.searchInProgress = false

        /* Either this is an initial search and there are no results, or it's an additional search.*/
        this.showSearchEndMessage = ((this.results.length == 0 && response.data.results.length == 0) || this.results.length > 1)

        this.results = this.results.concat(response.data.results)

        this.lowRank = response.data.lr
        this.highRank = response.data.hr
        this.lowId = response.data.li
        this.highId = response.data.hi

        this.moreAvailable = response.data.more_available

      })
      .catch((error) => {
        this.loading = false
        this.searchInProgress = false
        this.displayDisasterMessage = true
      })


    },
    entryClass: function(idx) {

      if (idx < 0) return ""

      let opts = this.autoCompleteOptions()

      if (this.selectedIdx == idx) {
        if (opts[idx].name.match("Inventor")) return "ac-focused-inventor";
        if (opts[idx].name.match("Application")) return "ac-focused-application";
        if (opts[idx].name.match("Compan")) return "ac-focused-company";
      } else {
        if (opts[idx].name.match("Inventor")) return "ac-inventor";
        if (opts[idx].name.match("Application")) return "ac-application";
        if (opts[idx].name.match("Compan")) return "ac-company";
      }
    },
    clearResultSet: function (event) {
      this.isLoading = false

      this.lowRank = ''
      this.highRank = ''
      this.lowId = ''
      this.highId = ''

      this.showSearchEndMessage = false

      this.results = []
    },
    gbutType: function () {
      return 'add'
    },
    showSearchBox: function() {
      if (this.record.entityType == 'application') return false

      return true
    },
    removeCard: function(cardRecord) {
      this.results.splice(this.results.indexOf(cardRecord), 1);
      // if (this.results.length == 0) // remove subpanel when last search result?
      //   this.closePanel()
    }

  }
}
</script>
