<template>
  <div class="panel-block sub-panel-head">
    <table style="width: 100%;">
      <tr>
        <td>
          {{ label }}
        </td>
        <td style="text-align: right; min-width: 70px;">
          <a @click.prevent="closePanel" class="icon" style="padding: 0; height: 30px; width: 30px;">
            <svg class="icon__cnt"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#ei-close-o-icon"></use></svg>
          </a>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  props: [
    'label',
    'record',
    'category'
  ],
  computed: {
    tagClass: function() {
      return `tag is-${this.category}`
    }
  },
  methods: {
    clipPanel: function() {
      this.$emit('clippanel', {})
    },
    closePanel: function() {
      this.$emit('hidesubpanel', {})
    }
  }
}
</script>
