<script>

import axios from 'axios'
import SubPanelHeader from './subpanel-header'

export default {
  props: [
    'record',
    'panelType',
    'refresh',
    'skipInitLoad',
    'mode',
    'cnt'
  ],
  data: function () {
    return {
      disasterState: false,
      loading: false,
      results: {}
    }
  },
  components: {
    SubPanelHeader
  },
  watch: {
    record: function(val) {
      this.reloadRecord()
    }
  },
  created: function() {
    if (this.skipInitLoad) return true
    this.reloadRecord()
  },
  methods: {
    reloadRecord: function() {
      this.loading = true
      this.results = {}

      axios.post('/network/graph/panel', {
        entity_type: this.record.entityType,
        entity_id: this.record.entityId,
        panel_type: this.panelType
      })
      .then((response) => {
        if ( response.data.success ) {
          this.loading = false
          this.results = response.data.results
        } else {
          this.disasterState = true
          this.loading = false
        }
      })
      .catch((error) => {
        this.disasterState = true
        this.loading = false
      })
    },
    closePanel: function() {
      this.$emit('hidesubpanel', {node: this.record, panelType: this.panelType})
    },
    clipPanel: function() {
      this.$emit('clippanel', {node: this.record, panelType: this.panelType})
    },
    addToGraph: function(eType, eId) {
      eventHub.$emit(
        "graph-add",
        { entityType: eType,
          entityId: eId,
          restriction: 'init',
          centerOn: true,
        });
    }
  }
}
</script>
