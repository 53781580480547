<template>
  <div :class="delayClass()" >
    <header class="card-header">
      <p class="card-header-title">
        <a :href="url" class="target-txt">
          {{ record.gene_name }}
          <span v-if="record.gene_name != record.protein_name">/ {{ record.protein_name}}</span></a>
      </p>
    </header>
    <table class="table card-content card-table">
      <tr>
        <td colspan="2">
          {{ record.pfullname}}
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import Card from './card';

export default {
  extends: Card,
  computed: {
    url: function () {
      return `/target/${this.record.id}`
    }
  }
}
</script>
