<template>
  <div>
    <div v-show="displayActive && (isLoading || results.length > 0 || displayDisasterMessage || showSearchEndMessage)" class="container tile-list-container" >

      <div class="columns" style="margin-bottom: 10px">
        <div class="column is-1" style="padding: 8px 0 0 20px">
          <a @click.prevent="clearDisplay">
            <div class="icon back-button-ish icon-lit">
              <svg class="icon__cnt"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#ei-arrow-left-icon"></use></svg>
            </div>
          </a>
        </div>
        <div class="column is-11" style="margin: auto">
          <p class="back-message title">Results for "{{ currentQuery }}"</p>
        </div>
      </div>

      <div class="tile is-ancestor" v-for="(chunk, i) in chunkedItems">
          <div v-for="(result, j) in chunk"class="tile is-parent" style="padding: 10px;" >
              <div class="tile is-child">
                <transition appear
                name="custom-classes-transition"
                enter-active-class="animated bounceInRight"
                leave-active-class="animated bounceOutRight">

                  <ApplicationCard :record="result" :idx="(i*chunkSize) + j" v-if="result.category == 'application'" class="card search-card"></ApplicationCard>
                  <CompanyCard :record="result" :idx="(i*chunkSize) + j" v-if="result.category == 'company'" class="card search-card"></CompanyCard>
                  <TargetCard :record="result" :idx="(i*chunkSize) + j" v-if="result.category == 'target'" class="card search-card"></TargetCard>
                  <DiseaseCard :record="result" :idx="(i*chunkSize) + j" v-if="result.category == 'disease'" class="card search-card"></DiseaseCard>
                  <InventorCard :record="result" :idx="(i*chunkSize) + j" v-if="result.category == 'inventor'" class="card search-card"></InventorCard>

                </transition>
              </div>
          </div>
        </div>

      <div v-if="results.length > 0 && !displayDisasterMessage" class="more-search">
        <button @click="requestResults" class="button is-large is-primary is-outlined" v-show="moreAvailable && !isLoading">
          More Results
        </button>
      </div>


      <div v-if="!moreAvailable && showSearchEndMessage" class="more-search">
        <div class="notification is-primary more-search">
          Could not find any suitable matches.
        </div>
      </div>

      <div v-show="isLoading">
        <div class="loader big-loader more-search" ></div>
      </div>

      <div v-show="displayDisasterMessage" style="text-align: center">
        <div class="icon has-text-danger" style="height: 40px; width: 40px;">
            <svg class="icon__cnt"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#ei-exclamation-icon"></use></svg>
          </div><br/>
          No data available.
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import InventorCard from 'components/tiles/card_inventor'
import CompanyCard from 'components/tiles/card_company'
import ApplicationCard from 'components/tiles/card_application'
import TargetCard from 'components/tiles/card_target'
import DiseaseCard from 'components/tiles/card_disease'

export default {
  data: function () {
    return {
      results: [],
      lowRank: '',
      highRank: '',
      lowId: '',
      highId: '',
      moreAvailable: false,
      showSearchEndMessage: false,
      chunkSize: 3,
      currentPath: '',
      currentQuery: '',
      currentParams: '',
      currentModifiers: {},
      currentCategory: '',
      isLoading: false,
      displayActive: false,
      displayDisasterMessage: false,
      title: ''
    }
  },
  props: [
    'token',
    'initPath',
  ],
  computed: {
    chunkedItems () {
      return this.chunk(this.results, this.chunkSize)
    }
  },
  created: function () {
    this.eventHub.$on('set-tiles', this.initTiles)
    this.eventHub.$on('clear-results', this.clearResultSet)
  },
  components: {
    InventorCard,
    CompanyCard,
    ApplicationCard,
    TargetCard,
    DiseaseCard
  },
  methods: {
    clearResultSet: function (event) {
      this.isLoading = false

      this.lowRank = ''
      this.highRank = ''
      this.lowId = ''
      this.highId = ''

      this.results = []
    },
    initTiles: function(data) {
      this.displayActive = true;
      this.clearResultSet();
      this.currentQuery = data.query
      this.currentCategory = data.category
      if (data.modifiers) {
        this.currentModifiers = data.modifiers;
      }
      this.requestResults()
    },
    removeCard: function(event) {
      this.results.splice(event.idx, 1);
    },
    requestResults: function() {
      this.isLoading = true
      this.displayDisasterMessage = false

      let params = {
        q: this.currentQuery,
        restriction: this.currentCategory,
        lr: this.lowRank,
        hr: this.highRank,
        li: this.lowId,
        hi: this.highId
      }

      if (this.currentModifiers) {
        Object.assign(params, this.currentModifiers)
      }

      axios.post(this.initPath, params)
      .then((response) => {

        /* Here we check for account required stuff. */
        if (!this.hasAccess(response, 'research')) return null

        this.eventHub.$emit('mark-search-complete', {})
        this.isLoading = false

        /* Either this is an initial search and there are no results, or it's an additional search.*/
        this.showSearchEndMessage = ((this.results.length == 0 && response.data.results.length == 0) || this.results.length > 1)

        this.results = this.results.concat(response.data.results)

        if (response.data.chunk_size) {
          this.chunkSize = response.data.chunk_size
        }

        this.lowRank = response.data.lr
        this.highRank = response.data.hr
        this.lowId = response.data.li
        this.highId = response.data.hi

        this.moreAvailable = response.data.more_available
      })
      .catch((error) => {
        this.isLoading = false
        this.displayDisasterMessage = true
        this.eventHub.$emit('mark-search-error', {})
      })
    },
    clearDisplay: function() {
      /* End the whole log-in/sign-up process by returning the user to whatever they were doing.*/
      this.displayActive = false

      /* Restore the content wrapper */
      let n = document.getElementById('content-wrapper')

      // n.style.opacity = 0
      n.style.display = 'block'
      n.className += " fadeIn animated ade-one"
    }
  }
}
</script>

<style scoped>
</style>
