<script>
export default {
  props: ['record', 'idx'],
  methods: {
    delayClass: function () {
      if (this.idx == 0) return 'ade-zero';
      if (this.idx == 1) return 'ade-one';
      if (this.idx == 2) return 'ade-two';
      if (this.idx == 3) return 'ade-three';
      if (this.idx == 4) return 'ade-four';
      if (this.idx == 5) return 'ade-five';
      if (this.idx == 6) return 'ade-six';
      if (this.idx == 7) return 'ade-seven';
      if (this.idx == 8) return 'ade-eight';
      if (this.idx == 9) return 'ade-nine';
      return 'ad-zero';
    }
  }
}
</script>
