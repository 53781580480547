//import 'babel-polyfill'
import Vue from 'vue'
import axios from 'axios'

import Search from '../search.vue'
import TileList from '../tiles.vue'
import Calendar from '../calendar.vue'
import Chat from '../chat.vue'
import Panels from '../panels.vue'
import UpgradeRequired from '../upgrade-required.vue'
import AccountRequired from '../account-required.vue'
import ChartApp from '../chart.vue'
import ActivityFeedApp from '../activity-feed.vue'
import WatchApp from '../watch.vue'

import UserSprinkles from '../sprinkles/users'
import ContentSprinkles from '../sprinkles/content'
import ReviewSprinkles from '../sprinkles/review'

let token = document.getElementsByName('csrf-token')[0].getAttribute('content')
axios.defaults.headers.common['X-CSRF-Token'] = token
axios.defaults.headers.common['Accept'] = 'application/json'

document.addEventListener('DOMContentLoaded', () => {
  /* This is a global variable hardwired in the application layout.  It allows for communicating to vue components. */
  eventHub = new Vue()

  /* global event bus gets added to all vue components */
  Vue.mixin({
    data: function () {
      return { eventHub: eventHub }
    },
    methods: {
      chunk(arr, n) {
        return arr.slice(0, (arr.length + n - 1)/n | 0).map((c, i) => arr.slice(n * i, n * i + n));
      },
      hasAccess(response, mode) {
        if (response.data.account_required == true) {
          this.eventHub.$emit("require-account", {mode: mode})
          return false
        } else if (response.data.upgrade_required == true) {
          this.eventHub.$emit("require-upgrade", {mode: mode})
          return false
        }
        return true
      }
    }
  })

  /* The search input application*/
  const searchApp = new Vue({
    render: h => h(Search),
    data: {}
  }).$mount('#search-tool-target')

  /* Sibling to the search input, it displays tiles, but is somewhat generic. */
  let tileNode = document.getElementById("tile-list-app")
  if (tileNode) {
    var TileInstance = Vue.extend(TileList)
    new TileInstance({
      el: tileNode,
      propsData: {
        token: token,
        initPath: tileNode.getAttribute("init-path"),
        initQuery: tileNode.getAttribute("init-query"),
        initParams: tileNode.getAttribute("init-params")
      }
    })
  }


  /* Application to prompt a user to upgrade and allow them to do so. */
  const accountApp = new Vue({
    render: h => h(AccountRequired),
    data:{}
  }).$mount('#account-required-app')

  /* Application to prompt a user to signup/sign in and allow them to do so. */
  const upgradeApp = new Vue({
    render: h => h(UpgradeRequired),
    data:{}
  }).$mount('#upgrade-required-app')


  /* Application for displaying the lifecycle-calendar application */
  if(document.getElementById("calendar-app")){
    const calendarApp = new Vue({
      render: h => h(Calendar, { props: { disableTableView: true } })
    }).$mount('#calendar-app')
  }

  /* Application for displaying the lifecycle-calendar application */
  if(document.getElementById("chat-app")){
    const calendarApp = new Vue({
      render: h => h(Chat, { })
    }).$mount('#chat-app')
  }

  let panelsNode = document.getElementById("panels-app");
  if(panelsNode){
    const panelsApp = new Vue({
      render: h => h(Panels, { props: { entityType: panelsNode.getAttribute("entity_type"), entityId: panelsNode.getAttribute("entity_id") }})
    }).$mount('#panels-app')
  }

  let watchAppNode = document.getElementById("watch-app");
  if(watchAppNode){
    var WatchAppInstance = Vue.extend(WatchApp)
    new WatchAppInstance({
      el: watchAppNode,
      propsData: {
        entityId: watchAppNode.getAttribute("entity-id"),
        entityType: watchAppNode.getAttribute("entity-type")
      }
    })
  }

  /* Create chart elements for each chart target */
  var nodes = document.querySelectorAll('.chart-app');
  var ChartInstance = Vue.extend(ChartApp)
  for (let n of nodes) {
    new ChartInstance({
      el: n,
      propsData: {
        token: n.getAttribute("token"),
        path: n.getAttribute("path"),
      }
    })
  }

  /* Create activity feed apps for each activity feed on a page. */
  var nodes = document.querySelectorAll('.activity-feed');
  var ActivityFeed = Vue.extend(ActivityFeedApp)
  for (let n of nodes) {
    new ActivityFeed({
      el: n,
      propsData: {
        token: n.getAttribute("token"),
        path: n.getAttribute("path"),
        mode: n.getAttribute("mode"),
        watchId: n.getAttribute("watch_id"),
        watchCategory: n.getAttribute("watch_category")
      }
    })
  }

  /*
   * call apply Sprinkles for our various asset pipeline-esque replacements
   *
   * Note: recommended to put page specific js inline.  These sprinkles should be
   *       somehwat generic, like docdl links or something.
   *
   */
  UserSprinkles.applySprinkle();
  ContentSprinkles.applySprinkle();
  ReviewSprinkles.applySprinkle();
})
