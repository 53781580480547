<template>
  <div>
    <div v-show="!displayDisasterMessage && loading" style="text-align: center">
     <div class="loader big-loader" ></div>
    </div>

    <canvas ref="canvas" :height="getHeight()" v-if="!displayDisasterMessage" ></canvas>

    <div v-show="displayDisasterMessage" style="text-align: center">
      <div class="icon has-text-info" style="height: 40px; width: 40px;">
          <svg class="icon__cnt"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#ei-exclamation-icon"></use></svg>
        </div><br/>
        No matching data found.
    </div>
  </div>
</template>

<script>
import axios from 'axios'

import Chart from 'chart.js';
import Vue from 'vue'


export default {
  data: function () {
    return {
      results: '',
      displayDisasterMessage: false,
      loading: true,
      maintainAspectRatio: true,
    }
  },
  props: [
    'token',
    'path',
  ],
  mounted: function () {
    axios.post(this.path, {
      authenticity_token: this.token
    })
    .then((response) => {
      this.results = response.data
      this.height = response.height
      this.width = response.width
      this.loading = false
      this.renderChart()
    })
    .catch((error) => {
      this.displayDisasterMessage = true
    });
  },
  methods: {
    getHeight: function() {
      if (this.results && this.results.height)
        return this.results.height
      return 75
    },
    renderChart: function() {
      Vue.nextTick( () => {
        new Chart(this.$refs.canvas, {
          type: this.results.type,
          data: this.results.data,
          options: this.results.options
        })
      })

    }
  }
}
</script>

<style scoped>
</style>
