export default {

  applySprinkle() {
    /* event handler for generic account required class that triggers signup */
    var nodes = document.querySelectorAll('.account-required');
    for (var i = 0; i < nodes.length; i++) {
      nodes[i].addEventListener('click', function(event) {
        event.preventDefault();
        eventHub.$emit("require-account", {mode: 'research'});
      })
    }

    var nodes = document.querySelectorAll('.ask-link');
    for (var i = 0; i < nodes.length; i++) {
      nodes[i].addEventListener('click', function(event) {
        event.preventDefault();
        let q = "";

        let template = event.target.getAttribute("template");
        if (template == "company-target") {
          let cname = event.target.getAttribute("company-name");
          let tname = event.target.getAttribute("target-name");
          q = `What is the relationship between ${cname} and ${tname}?`;
        } else if (template == "company-disease") {
          let cname = event.target.getAttribute("company-name");
          let dname = event.target.getAttribute("disease-name");
          q = `What is the relationship between ${cname} and ${dname}?`;
        } else if (template == "disease-target") {
          let tname = event.target.getAttribute("target-name");
          let dname = event.target.getAttribute("disease-name");
          q = `What is the relationship between ${tname} and ${dname}?`;
        }

        eventHub.$emit("ask-now", {question: q});
        let el = document.querySelector('#chat-submit');
        el.scrollIntoView();
      })
    }

    /* event handler for some back button state management */
    window.onpopstate = function(event) {
      if (event && event.state && event.state.clearable) {
        eventHub.$emit("clear-all", {});
      }
    }

    // Get all "navbar-burger" elements
    var $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);

    // Check if there are any navbar burgers
    if ($navbarBurgers.length > 0) {

      // Add a click event on each of them
      $navbarBurgers.forEach(function ($el) {
        $el.addEventListener('click', function () {

          // Get the target from the "data-target" attribute
          var target = $el.dataset.target;
          var $target = document.getElementById(target);

          // Toggle the class on both the "navbar-burger" and the "navbar-menu"
          $el.classList.toggle('is-active');
          $target.classList.toggle('is-active');

        });
      });
    }

    var nodes = document.querySelectorAll('.notification .delete');
    for (var i = 0; i < nodes.length; i++) {
      nodes[i].addEventListener('click', function(event) {
        var targetParentNode = event.target.parentNode;
        targetParentNode.style.display = "none";
      });
    }

    var nodes = document.querySelectorAll('.demo-link');
    for (var i = 0; i < nodes.length; i++) {
      nodes[i].addEventListener('click', function(event) {
        event.preventDefault();

        var elementId = event.target.getAttribute('target-id');
        var el = document.getElementById(elementId);
        if (el.style.display === 'block') {
          el.style.display = 'none';
          event.target.innerHTML = 'Show demo';
        } else {
          el.style.display = 'block';
          event.target.innerHTML = 'Hide demo';
        }
      });
    }

    var nodes = document.querySelectorAll('.show-claims');
    for (var i = 0; i < nodes.length; i++) {
      nodes[i].addEventListener('click', function(event) {
        event.preventDefault();

        document.querySelectorAll('.independent-claim').forEach((x) => x.style.display = "block");
        document.getElementById('more-claims').style.display = "none";
      });
    }
  }
}
