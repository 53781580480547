import { render, staticRenderFns } from "./account-required.vue?vue&type=template&id=2ccd2aae&scoped=true&"
import script from "./account-required.vue?vue&type=script&lang=js&"
export * from "./account-required.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ccd2aae",
  null
  
)

export default component.exports