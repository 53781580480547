<template>
  <div class="columns">
    <div class="column is-offset-one-quarter is-half">
      <div class="columns">
        <div class="column is-narrow">
          <span class="icon is-small is-left has-text-success" style="height: 100px; width: 100px;">
            <svg class="icon__cnt"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#ei-check-icon"></use></svg>
          </span>
        </div>
        <div class="column">
          <div v-show="successPlan == 'Research Pro'">
            Thank you for signing up with libpatent.  You will receive an email memorializing this purchase.<br/><br/>If you have any issues or requests, please contact <strong>berrydigital@gmail.com.com</strong> anytime.<br/><br/>Your support means a lot.  Thank you!<br/><br/>
          </div>
          <div v-show="successPlan == 'Monitoring Pro'">
            Thank you for signing up with libpatent.  You will receive an email memorializing this purchase.<br/><br/>If you have any issues or requests, please contact <strong>berrydigital@gmail.com.com</strong> anytime.<br/><br/>Your support means a lot.  Thank you!<br/><br/>
          </div>
          <div v-show="successPlan == 'Free'">
            Sorry the service did not meet your needs.<br/><br/>
            Your subscription has been deactivated.
          </div><br/><br/>

          <button @click="closeSuccess" class="button is-success is-large">OK</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  props: ['successPlan'],
  methods: {
    closeSuccess: function() {
      this.$emit('closesuccess', {})
    }
  }
}
</script>
