<template>
  <div>
    <div class="columns is-variable is-8" style="margin-top: 20px">

      <div class="column is-5">
        <div class="title">
          Monitoring Service Levels
        </div>
        <div class="subtitle">
          Keep track of the innovation marketplace.
        </div>
        <MonitoringUpgradeTable/>
      </div>

      <div class="column is-6">
        <div class="title">
          Checkout
        </div>
        <div class="subtitle">
          Your current monitoring plan is <span class="has-text-weight-semibold">{{ currentPlan }}</span>.  Select a plan below to change service levels.
        </div>

        <div class="columns">
          <div class="column plan-column">
            <div @click="selectFree" v-bind:class="[!monitoringProSelected ? 'box is-active' : 'box not-active']">
              <div class="is-size-4">Free Plan</div>
              <div class="is-size-5">$0</div>
              <p>&nbsp;</p>
            </div>
          </div>
          <div class="column plan-column">
            <div @click="selectPro" v-bind:class="[monitoringProSelected ? 'box is-active' : 'box not-active']">
              <div class="is-size-4">Pro Plan</div>
              <div class="is-size-5">$30</div>
              <p>billed montly</p>
            </div>
          </div>
        </div>



        <div v-if="(currentPlan == 'Free' && !monitoringProSelected) || (currentPlan == 'Monitoring Pro' && monitoringProSelected)">
          This is your current plan.  No action required.
        </div>
        <div v-else-if="currentPlan == 'Free' && monitoringProSelected">
          Please email <strong>berrydigital@gmail.com</strong> to arrange for access.
        </div>
        <div v-else-if="currentPlan == 'Monitoring Pro' && !monitoringProSelected" >
          <div class="columns">
            <div class="column is-10">
              <p>
                Sorry to see you go.<br/><br/>
                Please consider writing me at <strong>berrydigital@gmail.com.com</strong>, I'd welcome the chance to learn how the service could be improved.<br/><br/>
              </p>
              <button @click="executeDowngrade" class="button is-primary is-outlined">Switch to Free Plan</button>
            </div>
          </div>
        </div>

        <div v-if="problemMessage.length > 0" class="has-text-danger">
          {{ problemMessage }}
        </div>

      </div>

    </div>
  </div>
</template>

<script>
import axios from 'axios'
import MonitoringUpgradeTable from './feature_table.vue'
import PaymentForm from './payment_form.vue'
export default {
  data: function() {
    return {
      currentMonitoringPlanPath: '/account/current-plan',
      downgradePath: '/account/cancel-subscription',
      monitoringProSelected: false,
      currentPlan: 'unknown',
      problemMessage: ''
    }
  },
  props: ['token', 'stripetoken'],
  mounted: function() {
    axios.post(this.currentMonitoringPlanPath, {
      authenticity_token: this.token
    })
    .then((response) => {
      this.currentPlan = response.data.pretty_plan
      if (response.data.plan == 'free') {
        this.monitoringProSelected = false
      } else {
        this.monitoringProSelected = true
      }
    })
    .catch((error) => {
      this.currentPlan = 'unknown'
    });
  },
  components: {
    MonitoringUpgradeTable,
    PaymentForm
  },
  methods: {
    selectFree: function(event) {
      this.monitoringProSelected = false
    },
    selectPro: function(event) {
      this.monitoringProSelected = true
    },
    executeDowngrade: function(event) {
      axios.post(this.downgradePath, {
        authenticity_token: this.token,
        category: 'monitoring'
      })
      .then((response) => {
        if (response.data.success) {
          this.currentPlan = 'free'
          this.$emit('monitoringdowngradesuccess', event)
        } else {
          this.problemMessage = 'There was a technical problem cancelling your subscription.  Please contact berrydigital@gmail.com.com'
        }
      })
      .catch((error) => {
        this.problemMessage = 'There was a technical problem cancelling your subscription.  Please contact berrydigital@gmail.com.com'
      });
    },
    checkoutSuccessful: function(event) {
      this.$emit('monitoringupgradesuccess', event)
    }
  }

}
// <PaymentForm selectedPlan="monitoring_pro" :token="token" :stripetoken="stripetoken" v-show="monitoringProSelected" v-on:checkoutsuccess="checkoutSuccessful"/> // where contact message is.
</script>
