<template>
  <div id="watch-app">
    <div class="slider-check">
      <input type="checkbox"
              value="None"
              :id="'slide'"
              category="all"
              :name="'slide'"
              @click="toggleWatch"
              :checked="watchActive"
      />
      <label :for="'slide'"></label>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: [
    'entityId',
    'entityType'
  ],
  data: function () {
    return {
      token: '',
      initPath: '/watch/init',
      startPath: '/watch/start',
      stopPath: '/watch/stop',
      isWatched: false,
      watchOptions: [],
      watchActive: false,
      displayDisasterMessage: false
    }
  },
  created: function () {
    this.updateWatchOptions()
  },
  watch: {
    entityId: function(val) {
      this.updateWatchOptions() //reset for new entity
    },
    entityType: function(val) {
      this.updateWatchOptions()
    }
  },
  methods: {
    updateWatchOptions: function() {
      axios.post(this.initPath, {
        authenticity_token: this.token,
        entity_type: this.entityType,
        entity_id: this.entityId
      })
      .then((response) => {
        this.watchOptions = response.data.options
        this.watchActive = response.data.active
      })
      .catch((error) => {
        this.displayDisasterMessage = true
      });
    },
    generateTooltip: function() {
      return "Monitor activity on a custom dashboard.  Click the question mark to learn more.  Or activate the slider to try it out."
    },
    toggleWatch: function(event) {
      let checked = event.target.checked
      if (checked) {
        this.startWatch(event)
      } else {
        this.stopWatch(event)
      }
    },
    startWatch: function (event) {
      logevent('start-watch', this.entityType)

      axios.post(this.startPath, {
        entity_type: this.entityType,
        entity_id: this.entityId,
        cat: event.target.getAttribute("category"),
        authenticity_token: this.token
      })
      .then((response) => {
        /* First we check if a login or an upgrade is needed */
        if (!this.hasAccess(response, 'monitoring')) {
          event.target.checked = !event.target.checked // reset the toggle cause failed.
          return null
        }

        /* Set whether it was watched, and mark for failure ... because they should either
         *   need to sign in, upgrade, or have it be successful.
         */
        this.isWatched = response.data.success
        if (!response.data.success) this.displayDisasterMessage = true

        this.updateWatchOptions() // We now reset options, which should be updated if successful or failed

      })
      .catch((error) => {
        this.displayDisasterMessage = true
      })
    },
    stopWatch: function(event) {
      axios.post(this.stopPath, {
        entity_type: this.entityType,
        entity_id: this.entityId,
        cat: event.target.getAttribute("category"),
        authenticity_token: this.token
      })
      .then((response) => {

        /* First we check if a login or an upgrade is needed */
        if (!this.hasAccess(response, 'monitoring')) return null

        /* Set whether it was watched, and mark for failure ... because they should either
         *   need to sign in, upgrade, or have it be successful.
         */
        this.isWatched = !response.data.success
        if (!response.data.success) this.displayDisasterMessage = true


        this.updateWatchOptions() // We now reset options, which should be updated if successful or failed
      })
      .catch((error) => {
        this.displayDisasterMessage = true
      })
    }
  }
}
</script>

<style scoped>
</style>
