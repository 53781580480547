<template>
  <div id="chat" class="chat-app">
    <div class="container content">

      <div v-if="disasterState" style="text-align: center">
        <div class="icon has-text-danger" style="height: 40px; width: 40px;">
          <svg class="icon__cnt"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#ei-exclamation-icon"></use></svg>
        </div>
        <br/>
        Question service is not available at this time.  Please try later.
        <br/>
        <br/>
      </div>
      <div v-else-if="answers.length == 0">
        <h1 class="question">{{ invitation }}</h1>
        <p class="answer-invitation">
          I'm ready to answer your questions. Try typing something and hitting submit!
        </p>
      </div>
      <div v-else>
        <div v-for="(question, index) in questions">
          <article class="answer message answer-body">
            <p class="answer-question">{{ question }}</p><br/>
            <p v-html="answerHtmlContent(index)"></p>

            <div v-for="ch in chunk(answerLinks[index], 3)">
              <div class="columns">
                <div v-for="link in ch" class="column is-4">
                  <div class="box answer-link">
                    <a v-bind:href="link.url" :class="link.klass" target="_blank">{{ link.text }}</a>
                  </div>
                </div>
              </div>
            </div>
          </article>
          <br/>


          <br v-if="typeof answerLinks[index] != 'undefined' && answerLinks[index].length > 0"/>
        </div>
      </div>

      <div v-if="loading" style="text-align: center">
        <div class="loader big-loader" ></div>
        <br/>
      </div>

      <div class="input-container">
        <form @submit.prevent="submit" id="chat-submit">
          <input type="text" v-bind:class="[loading ? 'input-field inactive' : 'input-field']" v-model="currentQuestion" v-bind:onkeydown="[loading ? 'return false' : null]" placeholder="Type your question here">
          <button v-bind:class="[loading ? 'submit-button inactive' : 'submit-button']">Submit</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  props: [],
  data: function () {
    return {
      chatId: null,
      currentQuestion: '',
      questions: [],
      answers: [],
      answerLinks: [],
      path: '',
      token: '',
      type: '',
      invitation: 'A VALUE',
      loading: false,
      disasterState: false
    }
  },
  created: function () {
    let n = document.getElementById("chat-app");
    this.path = n.getAttribute("path");
    this.token = n.getAttribute("token");
    this.entityType = n.getAttribute("entity_type");
    this.entityId = n.getAttribute("entity_id");
    this.invitation = n.getAttribute("invitation");
    this.eventHub.$on('ask-now', this.askNow)
  },
  methods: {
    submit: function() {
      if (this.loading) { // don't submit while still working.
        return
      }
      this.disasterState = false
      this.loading = true

      axios.post(this.path, {
        chat_id: this.chatId,
        question: this.currentQuestion,
        type: this.entityType,
        id: this.entityId,
        authenticity_token: this.token
      })
      .then((response) => {
        this.chatId = response.data.chat_id;
        this.answers.push(response.data.answer)
        this.questions.push(this.currentQuestion)
        this.answerLinks.push(response.data.links)
        this.currentQuestion = ''
        this.loading = false
      })
      .catch((error) => {
        this.loading = false
        this.disasterState = true
      })
    },
    answerHtmlContent: function(index) {
      return this.answers[index];
    },
    chunk(arr, n) {
      if (typeof arr == "undefined" || arr.length === 0) {
        return [];
      }

      return arr.slice(0, (arr.length + n - 1)/n | 0).map((c, i) => arr.slice(n * i, n * i + n));
    },
    askNow: function(event) {
      this.currentQuestion = event.question;
      this.submit();
    }
  }
}
</script>

<style scoped>
</style>
