<template>
  <div>
    <div v-if="showDetails && !displayDisasterMessage">
      <transition appear name="custom-classes-transition" enter-active-class="animated bounceInLeft" leave-active-class="animated fadeOutLeft">
        <div class="graph-sidebar edge-panel">
          <SubSearch v-if="panel.panelType == 'subsearch'"
                          :record="activeNode"
                          :mode="panel.mode"
                          :cnt="panel.cnt"
                          skipInitLoad="true"
                          :panelType="panel.panelType"
                          v-on:hidesubpanel="removeSubPanel"></SubSearch>
          <SubCalendar v-else-if="panel.panelType == 'calendar'"
                          :record="activeNode"
                          :mode="panel.mode"
                          skipInitLoad="true"
                          :panelType="panel.panelType"
                          v-on:hidesubpanel="removeSubPanel"></SubCalendar>
      </div>
      </transition>
    </div>
  </div>
</template>

<script>
import SubSearch                    from './network-graph-components/subpanels/subsearch'
import SubCalendar                  from './network-graph-components/subpanels/subcalendar'

export default {
  data: function () {
    return {
      displayDisasterMessage: false,
      showDetails: false,
      panel: {},
    }
  },
  props: [
    'token',
    'entityType',
    'entityId'
  ],
  components: {
    SubSearch,
    SubCalendar
  },
  created: function() {
    this.eventHub.$on('activate-subsearch', this.activateSubsearch)
    this.eventHub.$on('activate-sub-clinical-trial-calendar', this.activateSubCal)
  },
  methods: {
    activateSubsearch: function(params) {
      this.showDetails = true;
      this.panel = {
        panelType: 'subsearch',
        recordType: this.entityType,
        mode: 'applications',
        cnt: 0
      };
    },
    activateSubCal: function(params) {
      this.showDetails = true;
      this.panel = {
        panelType: 'calendar',
        recordType: this.entityType,
        mode: 'clinical_trials',
        cnt: 0
      };
    },
    removeSubPanel: function(params) {
      this.showDetails = false;
      this.panel = {};
    }
  },
  computed: {
    activeNode: function() {
      return {
        id: `${this.entityId}${this.entityType}`,
        entityType: this.entityType,
        entityId: this.entityId,
      }
    }
  },
}
</script>

<style scoped>
</style>
