<template>
  <article class="media" :feed-type="record.type">
    <figure class="media-left feed-date">
      {{ record.event_at }}
    </figure>
    <figure class="media-left activity-feed-label">
      <a v-if="record.subject_url" :href="record.subject_url" :class="record.subject_klass">{{ record.subject_text }}</a>
      <span v-else :class="record.subject_klass">{{ record.subject_text }}</span>
    </figure>
    <div class="media-content">
      <div class="content">
        <div class="feed-title">
          <div>
            <a v-if="record.event_label_url" :href="record.event_label_url">{{ record.event_label }}</a>
            <span v-else>{{ record.event_label }}</span>
          </div>
        </div>
        <div class="feed-sc">
          {{ record.description }}
        </div>
        <div v-if="record.tags" class="feed-tags">
          <a v-for="(t, i) in record.tags"
             v-bind:key="i"
             :href="t.url" :class="tagKlass(t)">
              {{ t.name }}
          </a>
        </div>
      </div>
    </div>
  </article>

</template>

<script>
import Activity from './activity';

export default {
  extends: Activity,
  computed: {
    labelKlass: function () {
      return `is-medium ${this.record.event_label_klass}`
    },

  },
  methods: {
    tagKlass: function (t) {
      return `is-small ${t.klass}`
    }
  }
}
</script>
