<template>
  <div id="upgrade-app" v-if="displayActive" style="width: 100%;">

    <section class="section">
      <div class="container">

        <transition appear
              name="custom-classes-transition"
              enter-active-class="animated bounceInRight"
              leave-active-class="animated bounceOutRight">
          <div>

            <div class="columns">
              <div class="column is-1 ">
                <a @click.prevent="clearDisplay">
                  <div class="icon back-button icon-lit">
                    <svg class="icon__cnt"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#ei-arrow-left-icon"></use></svg>
                  </div>
                </a>
              </div>
              <div class="column is-5"></div>
            </div>

            <div class="columns is-variable is-8">

              <div class="column is-6 is-offset-3" >
                <div class="tabs is-large">
                  <ul>
                    <li v-bind:class="[activeForm == 'signup' ? 'is-active' : '']"><a @click="showSignUp">Create Account</a></li>
                    <li v-bind:class="[activeForm == 'signin' ? 'is-active' : '']"><a @click="showSignIn">Sign In</a></li>
                  </ul>
                </div>
                <SignUpForm :token="token"
                            v-on:signupsuccessful="signupSuccess"
                            v-on:signinform="showSignIn"
                            v-on:forgot="showForgotPassword"
                            v-show="activeForm == 'signup'"
                />
                <SignInForm :token="token"
                            v-on:signinsuccessful="signinSuccess"
                            v-on:signupform="showSignUp"
                            v-on:forgot="showForgotPassword"
                            v-show="activeForm == 'signin'"
                />

                <ForgotPasswordForm :token="token"
                                    v-on:forgotsuccessful="forgotSuccess"
                                    v-show="activeForm == 'forgot'"
                />
              </div>
            </div>


          </div>
        </transition>

      </div>
    </section>

  </div>
</template>

<script>
import FeatureTable from 'components/upgrade/feature_table.vue'
import SignUpForm from 'components/account/sign_up_form.vue'
import SignInForm from 'components/account/sign_in_form.vue'
import ForgotPasswordForm from 'components/account/forgot_password_form.vue'

export default {
  data: function () {
    return {
      path: '',
      token: '',
      entityId: '',
      displayActive: false,
      activeForm: 'signup',
      mode: '',
      restoreDivId: '' //One of the divs can be set back to display on cancel
    }
  },
  components: {
    FeatureTable,
    SignInForm,
    SignUpForm,
    ForgotPasswordForm
  },
  created: function () {
    let n = document.getElementById("account-required-app")
    this.token = n.getAttribute("token")
    let triggerMode = n.getAttribute("triggermode")

    if (triggerMode) {
      this.promptUser({mode: triggerMode})
    }

    this.eventHub.$on('require-account', this.promptUser)
    this.eventHub.$on('clear-all', this.clearDisplay)
  },
  methods: {
    promptUser: function (event) {
      logevent('account-required', event.mode)

      window.scrollTo(0, 0)

      this.restoreDivId = 'content-wrapper'

      document.getElementById('content-wrapper').style.display = 'none'

      /* Are we displaying monitoring or research feature benefits or just sign in, etc? */
      this.mode = event.mode
      if (this.mode == 'signin') {
        this.activeForm = 'signin'
      } else if (this.mode == 'signup') {
        this.activeForm = 'signup'
      }

      if (!window.location.toString().match("trigger")) {
        history.replaceState( {clearable: true} , window.title, window.location );
      }

      this.displayActive = true
    },
    accountForm: function (event) {
      this.displayActive = true
    },
    showSignUp: function(event) {
      this.activeForm = 'signup'
    },
    showSignIn: function(event) {
      this.activeForm = 'signin'
    },
    showForgotPassword: function(event) {
      this.activeForm = 'forgot'
    },
    clearDisplay: function() {
      /* End the whole log-in/sign-up process by returning the user to whatever they were doing.*/
      this.displayActive = false

      /* Restore the content wrapper */
      let n = document.getElementById('content-wrapper')
      if (this.restoreDivId != '') {
        n = document.getElementById(this.restoreDivId)
      }
      // n.style.opacity = 0
      n.style.display = 'block'
      n.className += " fadeIn animated ade-one"
    },
    signupSuccess: function(event) {
      let accountArea = document.getElementById('sign-up-sign-in')
      accountArea.innerHTML = `<a href="/watchlist">${event.name}</a>`
      this.clearDisplay()
    },
    signinSuccess: function(event) {
      let accountArea = document.getElementById('sign-up-sign-in');
      accountArea.innerHTML = '';

      let homeLink = document.getElementById('nav-home-link');
      homeLink.href = '/watchlist';
      homeLink.innerHTML = `${event.name}`;

      let els = document.querySelectorAll('.user-pres').forEach(el => el.style.display = 'block');

      this.clearDisplay();
    },
    forgotSuccess: function(event) {
      this.clearDisplay()
    }

  }
}
</script>

<style scoped>
</style>
