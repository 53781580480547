<template>
  <div :class="delayClass()" >
    <header class="card-header">
      <p class="card-header-title">
        <a v-bind:href="url">{{ record.t }}</a>
      </p>
    </header>
    <table class="table card-content card-table">
      <tr v-if="record.abstract">
        <td colspan="2">{{record.abstract}}</td>
      </tr>
      <tr v-if="record.anum">
        <td>Application Number</td>
        <td>{{record.anum}}</td>
      </tr>
      <tr v-if="!record.anum && record.pnum">
        <td>Publication Number</td>
        <td>{{record.pnum}}</td>
      </tr>
      <tr v-if="record.fd">
        <td>Filing Date</td>
        <td>{{record.fd}}</td>
      </tr>
      <tr v-if="record.cn">
        <td>Applicant</td>
        <td>{{record.cn}}</td>
      </tr>
    </table>

    <footer>

    </footer>
  </div>
</template>

<script>
import Card from './card';

export default {
  extends: Card,
  computed: {
    url: function () {
      return `/application/${this.record.anum}`
    },
    graphUrl: function() {
      return `/network/graph/application/${this.record.id}`
    }
  }
}
</script>
